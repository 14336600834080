// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
Rails.start();

// polyfills
require("polyfills/array_map")
import { polyfill } from 'es6-promise'; polyfill();
import 'whatwg-fetch';

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require('jquery')
require('bootstrap/dist/js/bootstrap')
require('bootstrap-table/dist/bootstrap-table')
require('bootstrap-table/dist/extensions/fixed-columns/bootstrap-table-fixed-columns')
window.$ = $

import('controllers')

import 'select2'
import 'select2/dist/css/select2.css'

require('rails/direct_uploads');
require('rails/confirm_modal');


// only allow png and jpg images in trix editor
window.addEventListener("trix-file-accept", function(event) {
  const acceptedTypes = ['image/jpeg', 'image/png']
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("Je kunt alleen jpeg of png plaatjes toevoegen")
  }
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

jQuery.fn.highlight = function(color, time) {
   $(this).each(function() {
        var el = $(this);
        el.before("<div/>")
        el.prev()
            .width(el.width())
            .height(el.height())
            .css({
                "position": "absolute",
                "background-color": color || "#ffff99",
                "opacity": ".9"
            })
            .fadeOut(time || 1000);
        el.prev().hide();
    });
};

$(document).on('ready turbolinks:load', function() {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();


  document.querySelectorAll('.custom-file-input').forEach(item => {
    item.addEventListener('change', event => {
      if (event.target.files.length == 1) {
        var fileName = event.target.files[0].name;
      } else {
        var fileName = event.target.files.length + ' bestanden geselecteerd';
      }

      var nextSibling = event.target.nextElementSibling
      nextSibling.innerText = fileName
    });
  })
});

$(document).on("turbolinks:before-cache", function() {
  $('select.select2').select2('destroy');
});

$(document).on('turbolinks:load', function() {
  $('select.select2').select2({ width: '100%' });
  $('select.select2').on('select2:select', function () {
    console.debug("fire native event for select2");
    let event = new Event('change', { bubbles: true }) // fire a native event
    this.dispatchEvent(event);
  });
});

// TODO move to Stimulus controller, this is a bit quick and dirty
window.initBootstrapTable = function() {
  setTimeout(function() {
    console.debug("Init bootstrap table")
    $('#quotationsTable').bootstrapTable();
    $('#quotationsTable').bootstrapTable('resetView');

    // add tooltip and popover again, somehow bootstrap table removes these
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover({trigger: "focus"});
  }, 500)
}

window.showOKModal = function(message) {
  let $dialog = $('#ok-modal');
  let $content = $dialog.find('#modal-content');
  $content.text(message);
  let $ok = $dialog.find('#ok-button')
  $ok.click(function(event) {
    event.preventDefault();
    $dialog.modal("hide");
  })
  $dialog.modal("show")
  return false;
};

window.showFlash = function(message, alertClass='alert-success', glyphClass='glyphicon-ok') {
  let html = `
    <div class="fixed-top flash">
      <div class="alert text-center fade show mb-0 ${alertClass}" data-controller='flash' role='alert' >
        <button class="close" data-dismiss="alert">×</button>
        <span class="glyphicon glyphClass" aria-hidden="true"></span>
        ${message}
      </div>
    </div>
  `;
  let main = document.getElementsByTagName("main")[0];
  main.insertAdjacentHTML("beforebegin", html);
}

window.scrollToSelector = function(selector, offset) {
  offset = offset || 0;
  const yOffset = -document.querySelector("#navbar").clientHeight + offset;
  const element = document.querySelector(selector);
  if (element) {
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  }
}

require("trix")
require("@rails/actiontext")
